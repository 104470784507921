import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Link from "../link"
import Grid from "../dotgrid/grid"
import { StoreConsumer } from "../../store"

const GridStyled = styled(Grid)`
  padding-top: ${rem(30)};
  padding-bottom: ${rem(30)};

  @media ${(props) => props.theme.xsmallDown} {
    padding-top: ${rem(15)};
    padding-bottom: ${rem(15)};
  }
`

const Container = styled.div`
  background-color: ${(props) => props.theme.colorGreyLight};
`

const BackToBlog = () => {
  return (
    <StoreConsumer>
      {({ translations }) => (
        <Container>
          <GridStyled className="styled-p styled">
            <Link
              to={translations[`blog_url`]}
              dangerouslySetInnerHTML={{
                __html: translations[`blog_see_all_articles`],
              }}
            />
          </GridStyled>
        </Container>
      )}
    </StoreConsumer>
  )
}

export default BackToBlog
