import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import Link from "../components/link"
import Hero from "../components/hero"
import Grid from "../components/dotgrid/grid"
import List from "../components/page-blog/list"
import BackToBlog from "../components/page-blog/back-to-blog"

const filterPostsByCategory = (posts, category) => {
  if (category) {
    posts = { ...posts }
    posts.edges = posts.edges.filter(({ node }) =>
      node.categories.find((c) => c.id === category)
    )
    return posts
  }
  return posts
}

const BackToMain = styled.div.attrs({ className: `styled-p-leading styled` })`
  margin-top: -5%;
  margin-bottom: 10%;

  @media ${(props) => props.theme.xsmallDown} {
    margin-top: -20%;
  }
`

const BlogCategoryPage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpCategory,
    postsAll,
    wp,
    siteMessage,
  } = data
  const { translations, menus } = pageContext

  const posts = useMemo(
    () => filterPostsByCategory(postsAll, wp.id),
    [postsAll, wp.id]
  )

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      siteMessage={siteMessage}
    >
      <MetaWp
        page={metaWpCategory}
        global={metaWpGlobal}
        defaults={metaDefaults}
      />

      <Hero
        data={{
          acf: {
            type: `color`,
            title: wp.name,
            pretitle: translations[`blog_title`],
          },
        }}
      />

      <BackToBlog />

      <List data={posts} />

      {posts.edges && posts.edges.length > 3 && (
        <Grid as={BackToMain}>
          <Link
            to={translations[`blog_url`]}
            dangerouslySetInnerHTML={{
              __html: translations[`blog_see_all_articles`],
            }}
          />
        </Grid>
      )}
    </Layout>
  )
}

BlogCategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogCategoryPage

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpCategoryFragment
    ...SiteMessageFragment

    ...PostsAllFragment

    wp: wordpressCategory(id: { eq: $id }) {
      id
      name
    }
  }
`
